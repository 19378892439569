<template>

    

    <div style="padding:20px;">


        <v-card class="" elevation="" max-width="" style="margin-top:20px;" >
            <v-card-text>

                <div style="">
                    <h1 >Robos Impressao Cardapio</h1>
                </div>
                <div style="margin-top:20px;margin-left:30px;">
                     <p style="margin-bottom:20px!important;"><b>Atualização Realizada</b>: Exibir Observações gerais nas impressões.</p>
                    <a href="Robos/Robo-Cardapio-04062024.exe">Robo Cardapio 04/06/2024</a>
                </div>





            </v-card-text>
               
        </v-card>


    </div>

    
</template>

<script>

export default {
    name:'Robos',
    components: {
    },
    data(){
        return{
            
        }
    },
    watch: {
        
    },
    methods:{
            
    },

    
    async mounted(){

    }
    
}
</script>

<style>
    .linhaFormulario{
        display: flex;
        flex-direction: row;
        /* background-color:red; */
        padding-left: 10%;
        padding-right: 10%;
    }

    .celularCampoForm{
        width:25%;
        padding:10px;
        /* background-color: green; */
    }

    .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
        color: rgba(0, 0, 0);
    }

</style>